import type { NextPage } from 'next';
import { Grid, ThemeProvider } from '@mui/material';
import theme from '../styles/theme';
import Image from 'next/image';

import logoEfigas from '../public/images/Logo_efigas_full.svg';
import logoGascaribe from '../public/images/Logo_Gases_Two_lines.svg';
import logoGuajira from '../public/images/Logo_gases_guajira_full.svg';
import CompanyButton from '../components/CompanyButton';
import { Policy } from 'ui';
import useUser from '../auth/useUser';

const Home: NextPage = () => {
  const currentUser = useUser();

  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        display="flex"
        direction="column"
        alignItems="center"
        marginTop={5}
      >
        <Policy user={currentUser} roles={['get_invoice_batch_GDC']}>
          <Grid item>
            <CompanyButton link="/GDC">
              <Image src={logoGascaribe} alt="Gascaribe" width={230} />
            </CompanyButton>
          </Grid>
        </Policy>
        <Policy user={currentUser} roles={['get_invoice_batch_EFG']}>
          <Grid item>
            <CompanyButton link="/EFG">
              <Image src={logoEfigas} alt="Efigas" width={230} />
            </CompanyButton>
          </Grid>
        </Policy>
        <Policy user={currentUser} roles={['get_invoice_batch_GDG']}>
          <Grid item>
            <CompanyButton link="/GDG">
              <Image src={logoGuajira} alt="Gasguajira" width={230} />
            </CompanyButton>
          </Grid>
        </Policy>
      </Grid>
    </ThemeProvider>
  );
};

export default Home;
