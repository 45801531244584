import { createTheme } from '@mui/material/styles';

// Update the Theme Typography's to add variant options
declare module '@mui/material/styles' {
  interface TypographyVariants {
    label: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    label?: React.CSSProperties;
  }
}

// Update the Typography's variant options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    label: true;
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#1050b3',
    },
    text: {
      primary: '#0F4459',
      secondary: '#A5A1A1',
      disabled: '#8B8B8B',
    },
    background: {
      default: '#FFFFFF',
    },
  },
  typography: {
    label: {
      marginRight: 8,
      fontWeight: 700,
    },
  },
});

export default theme;
